<template>
  <div>
    <graphTitle title="会员停车趋势分析">
      <el-tooltip placement="top" content="展示统计期间会员与非会员分别的停车次数趋势">
        <i class="el-icon-question my-icon" />
      </el-tooltip>
    </graphTitle>
    <div id="circularChart" class="circularChart"></div>
  </div>
</template>
<script>
// import hub from "./../../common/js/hub"
import graphTitle from "@/components/graphTitle";
export default {
  props: {
    tableData: {
      // type: Array,
      default: function () {
        return {};
      },
    },
    arrearageHistorytableData: {
      default: function () {
        return {};
      },
    },
    temporaryParktableData: {
      default: function () {
        return {};
      },
    },
    nightParktableData: {
      default: function () {
        return [];
      },
    },
    paySummaryReporttableData: {
      default: function () {
        return {};
      },
    },
    type: {
      default: "c1",
    },
  },
  data() {
    return {
      totalNum: 0,
      nameList: [],
      dataList: [],
      // 日期（共用）
      dateList: [],
      // 夜间停车 （以下）
      nightParknameList: [],
      nightParkdataList: [],
      // 营收总报表（以下）
      paySummaryReportdataList: [],
      seriesValue1: [
        {
          name: "应收总额",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#00FF00",
          },
          itemStyle: {
            normal: {
              color: "#00FF00",
            },
          },
        },
        {
          name: "欠费额",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "red",
          },
          itemStyle: {
            normal: {
              color: "red",
            },
          },
        },
      ],
      seriesValue2: [
        {
          name: "当日临停次数",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#00FF00",
          },
          itemStyle: {
            normal: {
              color: "#00FF00",
            },
          },
        },
      ],
      seriesValue3: [
        // {
        //   name: '停车记录总数',
        //   type: 'line',
        //   data: [0, 0, 0, 0, 0, 0, 0, 0],
        //   lineStyle: {
        //     color: '#FF3300'
        //   },
        //   itemStyle: {
        //     normal: {
        //       color: '#FF3300'
        //     }
        //   }
        // },
        {
          name: "会员停车次数",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#9933CC",
          },
          itemStyle: {
            normal: {
              color: "#9933CC",
            },
          },
        },
        {
          name: "非会员停车次数",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#CCCC00",
          },
          itemStyle: {
            normal: {
              color: "#CCCC00",
            },
          },
        },
      ],
      seriesValue4: [
        {
          name: "历史营收趋势",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#00FF00",
          },
          itemStyle: {
            normal: {
              color: "#00FF00",
            },
          },
        },
      ],
      textTitle1: "欠费金额趋势",
      textTitle2: "过去30日临时停车趋势",
      // textTitle3: '日间/夜间停车时长趋势',
      textTitle3: "",
      textTitle4: "历史营收趋势",
    };
  },
  components: {
    graphTitle,
  },
  watch: {
    tableData: {
      handler(value) {
        this.totalNum = 0;
        this.dataList = [];
        this.nameList = [];
        for (var i = 0; i < value.length; i++) {
          this.totalNum += value[i].value * 1;
          this.nameList.push(value[i].dataDate);
          this.dataList.push({
            name: value[i].name,
            value: Number(value[i].value / 100).toFixed(2),
          });
        }
        this.totalNum = (this.totalNum / 100).toFixed(2);
        this.drawGraph();
      },
      deep: true,
    },
    // 欠费历史分析（欠费金额趋势）
    arrearageHistorytableData: {
      handler(value) {
        this.dateList = [];
        this.seriesValue1[0].data = [];
        this.seriesValue1[1].data = [];
        for (var i = 0; i < value.length; i++) {
          //        this.totalNum += value[i].value * 1
          this.dateList.push(value[i].dataDate);
          // 应收金额
          this.seriesValue1[0].data.push(Number(value[i].shouldPay / 100).toFixed(2));
          // 欠费额
          this.seriesValue1[1].data.push(Number(value[i].arrearageAmount / 100).toFixed(2));
        }
        this.drawGraph();
      },
      deep: true,
    },
    // 临时停车统计（过去30日临时停车趋势）
    temporaryParktableData: {
      handler(value) {
        this.dateList = [];
        this.seriesValue2[0].data = [];
        for (var i = 0; i < value.length; i++) {
          //        this.totalNum += value[i].value * 1
          this.dateList.push(value[i].dataDate);
          // 过去30日临时停车趋势数据
          this.seriesValue2[0].data.push(value[i].shortParkNum);
        }
        this.drawGraph();
      },
      deep: true,
    },
    // 夜间停车记录趋势
    nightParktableData: {
      handler(value) {
        this.totalNum = 0;
        this.nightParknameList = [];
        this.dateList = [];
        this.seriesValue3[0].data = [];
        this.seriesValue3[1].data = [];
        // this.seriesValue3[2].data = [];
        // for (var i = value.length - 1; i > 0; i--) {
        for (var i = value.length - 1; i >= 0; i--) {
          // for (var i = 0; i < value.length; i++) {
          //        this.totalNum += value[i].value * 1
          this.dateList.push(value[i].dataDate);
          // 停车记录总数
          // this.seriesValue3[0].data.push(value[i].allPark);
          // 会员停车记录数
          this.seriesValue3[0].data.push(value[i].memberPark);
          // 非会员停车记录数
          this.seriesValue3[1].data.push(value[i].noneMemberPark);
        }
        this.drawGraph();
      },
      deep: true,
    },
    // 营收总报表的营收趋势
    paySummaryReporttableData: {
      handler(value) {
        this.totalNum = 0;
        let dateArr = [];
        this.dateList = [];
        this.seriesValue4[0].data = [];
        for (var i = 0; i < value.length; i++) {
          // this.totalNum += value[i].value * 1
          dateArr.push(value[i].date);
          this.seriesValue4[0].data.push(Number(value[i].amount / 100).toFixed(2));
        }
        this.dateList = this.uniq(dateArr);
        this.drawGraph();
      },
      deep: true,
    },
  },
  methods: {
    // 去重
    uniq(array) {
      var temp = [];
      for (var i = 0; i < array.length; i++) {
        if (temp.indexOf(array[i]) == -1) {
          temp.push(array[i]);
        }
      }
      return temp;
    },
    drawGraph() {
      let that = this;
      let circularChart = this.$echarts.init(document.getElementById("circularChart"));
      let option = {
        title: {
          text:
            that.type == "c1"
              ? that.textTitle1
              : that.type == "c2"
              ? that.textTitle2
              : that.type == "c3"
              ? that.textTitle3
              : that.textTitle4,
        },
        legend: {
          data:
            that.type == "c1"
              ? ["应收总额", "欠费额"]
              : that.type == "c2"
              ? ["当日临停次数"]
              : that.type == "c3"
              ? ["非会员停车次数", "会员停车次数"]
              : ["历史营收趋势"],
        },
        tooltip: {
          trigger: "axis",
          formatter(params) {
            const res = that.nightParktableData.find((item) => {
              return item.dataDate == params[0].axisValue;
            });
            let tooltip = "";
            tooltip += res.dataDate + "</br>";
            params.forEach(e => {
              tooltip += e.marker + `${e.seriesName}: ${e.value}(次)` + "</br>";
            })
            tooltip += `停车记录总数: ${res.allPark}(次)` + "</br>";
            return tooltip;
          },
        },
        toolbox: {
          show: true,
          feature: {
            //		            magicType: {show: true, type: ['stack', 'tiled']},
            //		            saveAsImage: {show: true}
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.dateList,
        },
        yAxis: {
          type: "value",
          name: "停车次数(次)",
          axisLabel: {
            formatter: function (value) {
              return value;
            },
          },
        },
        series:
          that.type == "c1"
            ? that.seriesValue1
            : that.type == "c2"
            ? that.seriesValue2
            : that.type == "c3"
            ? that.seriesValue3
            : that.seriesValue4,
      };
      circularChart.setOption(option, true);
    },
  },
  mounted() {
    this.drawGraph();
  },
  created() {},
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.circularChart
  width 100%
  height 300px
  overflow hidden
</style>
