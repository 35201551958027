<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="">
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Belonging_operator')">
                <a-operation-select ref="operationSelect"></a-operation-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
                <a-park-type-select ref="parkTypeSelect"></a-park-type-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Date_search')">
                <a-date-picker ref="datePicker" :selectkeys="selectkeys"></a-date-picker>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="searchNightParkList"
                :loading="loading"
                >查询
              </el-button>
              <el-button type="info" icon="el-icon-refresh" @click="clearData()" :loading="loading"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <!-- 其他按钮 -->
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
              <el-button
                type="info"
                icon="el-icon-upload2"
                @click="exportExcelReport()"
                :loading="loading"
                v-if="$route.meta.authority.button.export"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>
          <!-- <el-form-item :label="$t('searchModule.date')"
                      prop="dateType"
                      style="width:200px;">
          <el-select v-model.trim="formInline.dateType"
                     filterable
                     size="15"
                     style="width:100px;"
                    >
            <el-option label="日"
                       value="day">日</el-option>
            <el-option label="月"
                       value="month">月</el-option>
          </el-select>
        </el-form-item>
        <timeRangePick
            @timeChange="timeChange"
            ref="timeRangePicker"
            :defalutDate = "defaultTime"
            :type = "formInline.dateType == 'day' ? 'daterange' : 'monthrange'"
            :valueFormat = "formInline.dateType == 'day' ? 'yyyy-MM-dd' : 'yyyy-MM'"
          /> -->
          <!-- <el-date-picker v-model="startTime"
                        :picker-options="pickerOptions"
                        type="date"
                        placeholder="选择日期"
                        v-show="formInline.dateType == 'day'"
                        style="margin-right: 10px;"
                        ></el-date-picker>
        <span v-show="formInline.dateType == 'day'">至</span>
        <el-date-picker v-model="endTime"
                        :picker-options="pickerOptions"
                        type="date"
                        placeholder="选择日期"
                        v-show="formInline.dateType == 'day'"
                        style="margin-right: 10px;margin-left: 10px;"></el-date-picker>
        <el-date-picker v-model="startTime"
                        :picker-options="pickerOptions"
                        type="month"
                        placeholder="选择月"
                        v-show="formInline.dateType == 'month'"
                        style="margin-right: 10px;"></el-date-picker>
        <span v-show="formInline.dateType == 'month'">至</span>
        <el-date-picker v-model="endTime"
                        :picker-options="pickerOptions"
                        type="month"
                        placeholder="选择月"
                        v-show="formInline.dateType == 'month'"
                        style="margin-right: 10px;margin-left: 10px;"></el-date-picker> -->
        </el-form>
        <!--折线图-->
      </div>
      <CircularChart
        :nightParktableData="tableData"
        :type="type3"
        class="graphShadow paddingT10 paddingL20 marginTB20"
      ></CircularChart>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10 paddingT10">
        <el-table v-loading="loading" :data="tableList" style="width: 100%">
          <!-- <el-table-column label="时间" width="" align = "center">
              <template slot-scope="scope">
                <span>{{scope.row.dataDate}}</span>
              </template>
            </el-table-column> -->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
// import timeRangePick from "@/components/timePicker"
import { getZeroOrLastDateTime } from "@/common/js/utils";
// 折线图
import CircularChart from "./ParkingChart.vue";
import { exportExcelNew, dateFormat, formatWeekDate } from "../../../../common/js/public.js";

export default {
  // 停车分析
  name: "Parkinganalysis",
  data() {
    const startTime = getZeroOrLastDateTime("yyyy-MM-dd", -31);
    const endTime = getZeroOrLastDateTime("yyyy-MM-dd", -1);
    return {
      selectkeys: ["date", "month", "week", "year"],
      defaultTime: [startTime, endTime],
      tableData: [],
      tableList: [],
      loading: false,
      // pickerOptions: {
      //   disabledDate (time) {
      //     return time.getTime() > endTime;
      //   }
      // },
      formInline: {
        dateType: "day",
        startTime: "",
        endTime: "",
      },
      // 折线图样式
      type3: "c3",
      type2: "c2",
      tableCols: [
        {
          prop: "_dataDate",
          label: "时间",
          width: "",
        },
        {
          prop: "memberPark",
          label: "会员停车次数(次)",
          width: "",
        },
        {
          prop: "noneMemberPark",
          label: "非会员停车次数(次)",
          width: "",
        },
        {
          prop: "memberRatio",
          label: "会员停车占比",
          width: "",
          formatter: (row, column, cellValue, index) => {
            // console.log(cellValue.toFixed(2))
            return (cellValue * 100).toFixed(2) + "%";
          },
        },
      ],
    };
  },
  methods: {
    clearData() {
      this.$refs.operationSelect.clear();
      this.$refs.datePicker.clear();
      this.$refs.parkTypeSelect.clear();
    },
    getDateType() {
      let dateType = this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "";
      let val = "";
      switch (dateType) {
        case 1:
          val = "day";
          break;
        case 5:
          val = "week";
          break;
        case 2:
          val = "month";
          break;
        case 3:
          val = "year";
          break;
        default:
          break;
      }
      return val;
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    // sortChange(a, b) {  // 排序
    //     return a.dataDate-b.dataDate
    // },
    // 查询夜间停车列表
    searchNightParkList() {
      // let flag = this.showLog();
      // if (flag) {
      this.loading = true;
      this.$axios
        .get("/acb/2.0/memberStatistic/parkingTendency", {
          data: {
            dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
            // dateType: this.formInline.dateType, // 必填，day/month
            // startDate: this.formInline.startTime, // 必填，格式要求day:yyyy-MM-dd,month:yyyy-MM
            // endDate: this.formInline.endTime, // 必填，格式要求day:yyyy-MM-dd,month:yyyy-MM
          },
        })
        .then((res) => {
          // res.value[0].memberPark = 6000
          this.tableData = [...res.value].reverse();
          this.tableList = res.value;
          // this.tableList.reverse();
          // 周粒度需要单独处理

          this.tableList.forEach((item) => {
            if (this.$refs.datePicker.getDateType() == 3) {
              if (item.dataDate) {
                item._dataDate = formatWeekDate(item.dataDate);
              } else {
                item._dataDate = item.dataDate;
              }
            } else {
              item._dataDate = item.dataDate;
            }
          });
          // this.tableData_ = [...this.tableData].reverse();
          // console.log(this.tableList)
        })
        .finally(() => {
          this.loading = false;
        });
      // }
    },
    // 导出
    exportExcelReport() {
      // console.log(this.formInline.dataDate);
      // this.formInline.dataDate = this.formInline.dataDate ? this.formInline.dataDate : this.dateFormat(new Date(), 'yyyy-MM')
      // exportExcelNew('/acb/2.0/memberStatistic/exportParkingTendency', this.formInline, 'get')
      // let flag = this.showLog();
      // if (!flag) return;
      let opt = {};
      opt = {
        dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
      };
      exportExcelNew("/acb/2.0/memberStatistic/exportParkingTendency", opt);
    },
    // getTimeNow () {
    //   const startTime = new Date();
    //   const endTime = new Date();
    //   if (this.formInline.dateType == "day") {
    //     startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
    //     endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
    //   } else {
    //     startTime.setTime(startTime.getTime() - 365 * 24 * 3600 * 1000);
    //     endTime.setTime(endTime.getTime() - 31 * 24 * 3600 * 1000);
    //   }
    //   this.startTime = dateFormat(startTime, 'yyyy-MM-dd');
    //   this.endTime = dateFormat(endTime, 'yyyy-MM-dd');
    // },
    // 时间判断
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        let diff = "";
        if (this.formInline.dateType === "day") {
          if (time >= 31) {
            this.$alert("查询时间不能大于31天");
            return false;
          } else {
            return true;
          }
        } else {
          if (time > 365) {
            this.$alert("查询时间不能大于12个月");
            return false;
          } else {
            return true;
          }
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
  },
  mounted() {
    // this.getTimeNow();
    this.searchNightParkList();
  },
  components: {
    CircularChart,
    // timeRangePick
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus"></style>
